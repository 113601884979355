<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1">
        <div class="content animate__animated animate__fadeIn">
          <div class="title1">知路导航自主核心创新</div>
          <div class="title2">高精度音频室内定位技术</div>
          <div class="title3">
            通过在室内空间部署知路导航自主研发的音频发射基站，<br />
            手机等终端即可通过麦克风可接收基站发射的音频信号，<br />
            终端通过测量音频信号从发射器到手机的传播时间，再乘<br />
            以音速即可计算传播距离，进而可解算位置。
          </div>
        </div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content row_problem  animate__animated animate__bounceInRight">
          <div class="col_2 p1"></div>
          <div class="col_2 p2">
            <div class="t1">万物互联感知痛点：<br />北斗系统无法室内定位</div>
            <div  class="t2">
              在室外主要利用卫星导航定位系统进行位置感知，但卫星导航无法在无线电<br />
              信号被阻挡的区域（统称“室内”区域）进行定位，室内区域亟需其他技术<br />手段进行定位。<br />
              主要工业级室内定位技术为谷歌的WiFi(RTT)、苹果生态的UWB(TWR)、<br />
              蓝牙(AOA)以及音频(TDOA)等四类技术 ，知路导航研发的音频高精度定位<br />
              技术为我国创新研发，自主可控，支持任意手机。
            </div>
          </div>
        </div>
      </div>
      <div class="row_content">
        <div class="content">
          <div style="text-align: center; color: #000000; font-size: 18pt; line-height: 30px; margin: 30px 0">工业级技术高精度室内定位技术对比</div>
          <div style="text-align: center; margin-bottom: 80px"><img src="/images/technology/compare.png" width="1125px" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "technology.scss";
</style>
